import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
      <section role="main" className="bg">
        <div className="container">
            <div className="row">
              <div className="sub-hero-content sub-hero">
                <div className="sub-hero-text full">
                    <h1
                    >Error: 404</h1>
                    <p className="lead">
                      The page you are looking for does not exist or has been deleted.
                    </p>
                    <div className="btn-row">
                      <Link to="/" className="btn btn-lg">Go to home page</Link>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </section>
  </Layout>
)

export default NotFoundPage
